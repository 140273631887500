import React, { useEffect, useState, useCallback, useRef } from "react"

import InvoiceItem from "./invoiceItem"
import InvoiceOption from "./invoiceOption"
import Tax from "./tax"
import DueOptions from "./dueOptions"
import AddSku from "./addSku"
import TypeNStatus from "./typeNStatus"
import PaymentMethod from "./paymentMethod"
import UserExperienceFlow from "../Doc/UserExperienceFlow"
import { setAdditionalOptionsArray, setAutomationOptionsArray } from "../Doc/Service/docHelper"
import OtherOptions from "./Form/otherOptions"
import { getUserName } from "../Doc/Service/docHelper"
import PanelToggle from "../Item/panelToggle"
import DhAuto from "../Item/dhAuto/dhAuto"
import InstallationOptions from "./installationOptions"
import Accounting from "./accounting/accounting"

const Details = ({
  selectedDocId,
  fileId,
  states,
  setStates,
  items,
  setItems,
  options,
  setOptions,
  comState,
  setComState,
  payor,
  tags,
  setTags,
  tempTags,
  setTempTags,
  previewUrl,
  setShowHistory,
  setHistoryType,
  setHistoryLabel,
  setDefaultMessage,
  emails,
  phones,
  setHistoryValue,
}) => {
  const driveType = "invoice"

  const [otherOption, setOtherOption] = useState(""),
    [selectedOption, setSelectedOption] = useState(""),
    [inPersonMode, setInPersonMode] = useState(""),
    [forwardDomain, setForwardDomain] = useState(""),
    [additionalOptions, setAdditionalOptions] = useState([]),
    [inPersonModeFileType, setInPersonModeFileType] = useState(""),
    [inPersonModeFileId, setInPersonModeFileId] = useState(""),
    [inPersonModeFileURL, setInPersonModeFileURL] = useState(""),
    [onPageDownloadFile, setOnPageDownloadFile] = useState(""),
    [toECommerceItem, setToECommerceItem] = useState(""),
    [forwardUrl, setForwardUrl] = useState(""),
    [primaryEmail, setPrimaryEmail] = useState(""),
    [toScheduler, setToScheduler] = useState("")

  const [subTotal, setSubTotal] = useState(0)
  const [totalInternal, setTotalInternal] = useState(0)
  const [total, setTotal] = useState(0)
  const [showAddOption, setShowAddOption] = useState(false)

  const [subTotalWithOptions, setSubTotalWithOptions] = useState(0)
  const [taxSubtotal, setTaxSubtotal] = useState(0)

  const [due, setDue] = useState(0)
  const [showSkuModal, setShowSkuModal] = useState(false)

  const updateItem = useCallback(
    updatedItem => {
      setItems(prevItems => {
        const updatedItems = prevItems.map(item => (item.index === updatedItem.index ? updatedItem : item))
        return updatedItems
      })
    },
    [setItems]
  )

  const deleteItem = toDeleteItem => {
    const updatedItems = items.filter(item => item.index !== toDeleteItem.index)
    setItems([...updatedItems])
  }

  useEffect(() => {
    if (comState.saveData && comState.level === 0 && !comState.upToDate.ueFlow) {
      const formData = new FormData(formRef.current)

      const ueFlow = {
        other_option: formData.get("otherOption"),
        selected_option: formData.get("selectedOption"),
        forward_domain: formData.get("forward_domain"),
        forward_url: formData.get("forward_url"),
        in_person_mode: formData.get("in_person_mode"),
        in_person_mode_file_type: formData.get("in_person_mode_file_type"),
        in_person_mode_file_id: formData.get("in_person_mode_file_id"),
        in_person_mode_to_url: formData.get("in_person_mode_to_url"),
        on_page_download_file: formData.get("on_page_download_file"),
        to_ecommerce_item: formData.get("to_ecommerce_item"),
        to_scheduler: formData.get("to_scheduler"),
        additional_options: setAdditionalOptionsArray(formData),
      }

      const form = { ...states.form, ueFlow }
      setStates(prevStates => {
        return { ...prevStates, form }
      })

      setComState(prevComState => {
        return { ...prevComState, level: 1, upToDate: { ...prevComState.upToDate, ueFlow: true } }
      })
    }
  }, [comState.saveData, comState.level])

  useEffect(() => {
    const total = items.reduce((total, item) => total + item.quantity * item.price, 0)
    setSubTotal(total)
    const internalPrice = items.reduce(
      (internalPrice, item) =>
        internalPrice + (item.internalPrice !== undefined && item.price !== 0 ? item.internalPrice : 0),
      0
    )
    setTotalInternal(internalPrice)
  }, [items])

  useEffect(() => {
    setTotal(subTotalWithOptions + taxSubtotal)
  }, [subTotalWithOptions, taxSubtotal])

  useEffect(() => {
    const { form } = states

    setSelectedOption(form.ueFlow.selected_option ?? "")
    setForwardDomain(form.ueFlow.forward_domain ?? "")
    setForwardUrl(form.ueFlow.forward_url ?? "")
    setAdditionalOptions(form.ueFlow.additional_options ?? "")
    setInPersonMode(form.ueFlow.in_person_mode ?? "")
    setInPersonModeFileType(form.ueFlow.in_person_mode_file_type ?? "")
    setInPersonModeFileId(form.ueFlow.in_person_mode_file_id ?? "")
    setInPersonModeFileURL(form.ueFlow.in_person_mode_to_url ?? "")
    setOnPageDownloadFile(form.ueFlow.on_page_download_file ?? "")
    setToECommerceItem(form.ueFlow.to_ecommerce_item ?? "")
    setToScheduler(form.ueFlow.to_scheduler ?? "")
  }, [states.form.ueFlow])

  const addNewItem = (hourly = false) => {
    const index =
      items.length === 0
        ? 0
        : items.reduce((maxIndex, item) => (item.index > maxIndex ? item.index : maxIndex), -Infinity) + 1
    let newItem = { description: "", recurring: false, index: index, quantity: 1, price: 0 }
    if (hourly) newItem = { ...newItem, label: "Hourly", internalPrice: 0 }

    setItems([...items, newItem])
  }

  const doNothing = e => {
    e.preventDefault()
  }

  const formRef = useRef(null)

  return (
    <>
      <div className="col-lg-12">
        <div className="float-end">
          <ul className="nav nav-tabs swiftCloudTabs d-md-flex" role="tablist">
            <li className="nav-item" role="presentation">
              <a className="nav-link active" href="#" onClick={doNothing}>
                <i className="bi bi-pencil-square"></i> Edit
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                href={`${process.env.GATSBY_PREVIEW_URL}/${getUserName()}/invoice/${fileId}`}
                target="_blank"
              >
                <i className="bi bi-box-arrow-up-right"></i> Preview
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="panel panel-default" id="invoice_container">
        <TypeNStatus states={states} setStates={setStates} />
        <div id="invoicePanel" className="panel-collapse collapse show">
          <div className="panel-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="tblInvoiceContainer">
                  <div className="tblHead">
                    <div className="w45">Item / Memo</div>
                    <div className="w12 text-end">Quantity</div>
                    <div className="w12 text-end">Price</div>
                    <div className="w15 text-end">
                      <span className="internalCostInfo d-none">Internal Cost</span>&nbsp;
                    </div>
                    <div className="w12 text-end">Sub Total</div>
                    <div className="w4">&nbsp;</div>
                  </div>
                  <div className="tblBody">
                    {items.map(item => {
                      return (
                        <InvoiceItem
                          item={item}
                          key={item.index}
                          updateItem={updateItem}
                          deleteItem={deleteItem}
                        />
                      )
                    })}
                  </div>
                </div>
                <table className="table dataTable no-footer tblInvoice">
                  <tbody>
                    <tr>
                      <td>
                        <button
                          type="button"
                          className="btnAddNewGreenDashed btnAddNewItem"
                          data-bs-toggle="tooltip"
                          title="Add New"
                          onClick={() => addNewItem()}
                        >
                          <i className="bi bi-plus-lg"></i>
                        </button>{" "}
                        <button type="button" className="btnAddNewGreenDashed btnAddShoppingCart">
                          <span
                            data-bs-toggle="tooltip"
                            title="Add From SKU's"
                            onClick={() => setShowSkuModal(true)}
                          >
                            <i className="bi bi-cart-plus-fill"></i>
                          </span>
                        </button>{" "}
                        <button
                          type="button"
                          className="btnAddNewGreenDashed btnAddHourlyDefaultRate"
                          data-bs-toggle="tooltip"
                          title="Add Hourly"
                          onClick={() => addNewItem(true)}
                        >
                          <i className="bi bi-clock"></i>
                        </button>{" "}
                      </td>
                      <td colSpan="2" className="text-end actionCol w32">
                        <a
                          onClick={() => setShowAddOption(!showAddOption)}
                          className="toggleOptions moreOpt"
                          data-bs-toggle="tooltip"
                          title="More Options"
                          role="button"
                          tabIndex={0}
                        >
                          <i className="bi bi-three-dots"></i>
                        </a>
                      </td>
                    </tr>

                    <tr>
                      <td></td>
                      <td className="text-end w16">
                        <strong>Sub Total:</strong>
                      </td>
                      <td className="text-end subTotal w16">${subTotal.toFixed(2)}</td>
                    </tr>

                    <InvoiceOption
                      showAddOption={showAddOption}
                      options={options}
                      setOptions={setOptions}
                      total={subTotal}
                      setNextTotal={setSubTotalWithOptions}
                    />
                    <Tax
                      subTotal={subTotalWithOptions}
                      taxSubtotal={taxSubtotal}
                      setTaxSubtotal={setTaxSubtotal}
                      states={states}
                      setStates={setStates}
                    />

                    <tr className="totalRow">
                      <td>
                        <span className="internalCostContainer">
                          Internal Cost Total:{" "}
                          <span className="text-right internalCostTotal"> ${totalInternal.toFixed(2)}</span>
                        </span>
                        <span className="grossMarginContainer">
                          Gross Margin:{" "}
                          <span className="text-right grossMarginTotal">
                            ${(total - totalInternal).toFixed(2)}
                          </span>
                        </span>
                      </td>
                      <td className="text-end blue">
                        <strong>Total:</strong>
                        <input type="hidden" name="finalTotalHidden" id="finalTotalHidden" />
                      </td>
                      <td className="text-end finalTotal blue">${total.toFixed(2)}</td>
                    </tr>

                    <DueOptions
                      total={total}
                      setDue={setDue}
                      due={due}
                      states={states}
                      setStates={setStates}
                    />
                  </tbody>
                </table>
              </div>
            </div>

            <PaymentMethod states={states} setStates={setStates} due={due} payor={payor} />
          </div>
        </div>
      </div>

      <form ref={formRef}>
        {/* FOR THEM: User Experience Flow */}
        {states.showForThemPanel && (
          <UserExperienceFlow
            otherOption={otherOption}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            forwardDomain={forwardDomain}
            setForwardDomain={setForwardDomain}
            forwardUrl={forwardUrl}
            setForwardUrl={setForwardUrl}
            additionalOptions={additionalOptions}
            setAdditionalOptions={setAdditionalOptions}
            inPersonMode={inPersonMode}
            setInPersonMode={setInPersonMode}
            inPersonModeFileType={inPersonModeFileType}
            setInPersonModeFileType={setInPersonModeFileType}
            inPersonModeFileId={inPersonModeFileId}
            inPersonModeFileURL={inPersonModeFileURL}
            onPageDownloadFile={onPageDownloadFile}
            toECommerceItem={toECommerceItem}
            setOtherOption={setOtherOption}
            selectedDocId={selectedDocId}
            primaryEmail={primaryEmail}
            driveType={driveType}
            setToECommerceItem={setToECommerceItem}
            toScheduler={toScheduler}
            setToScheduler={setToScheduler}
          />
        )}

        {/*FOR YOU: Data Handling & Automation*/}
        {states.showDhaPanel && <DhAuto states={states} setStates={setStates} />}

        {/*Installation Options*/}
        {states.showInstallationPanel && (
          <InstallationOptions
            states={states}
            setStates={setStates}
            previewUrl={previewUrl}
            setShowHistory={setShowHistory}
            setHistoryType={setHistoryType}
            setHistoryLabel={setHistoryLabel}
            setDefaultMessage={setDefaultMessage}
            emails={emails}
            phones={phones}
            setHistoryValue={setHistoryValue}
          />
        )}

        {states.showAccountingPanel && <Accounting states={states} setStates={setStates} />}

        {/* Other options */}
        {states.showMoreDetailsPanel && (
          <OtherOptions
            states={states}
            setStates={setStates}
            tags={tags}
            setTags={setTags}
            tempTags={tempTags}
            setTempTags={setTempTags}
          />
        )}

        <PanelToggle states={states} setStates={setStates} />
      </form>

      <AddSku
        items={items}
        setItems={setItems}
        showSkuModal={showSkuModal}
        setShowSkuModal={setShowSkuModal}
      />
    </>
  )
}

export default Details
