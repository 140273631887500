import React, { useState, useEffect } from "react"
import Layout from "../../../components/Layout/layout"
import People from "./people"
import Details from "./details"

import "bootstrap/dist/css/bootstrap.min.css"
import "../../../css/swiftcloud_global.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "../../Layout/menu.css"
import "../../../css/jquery.dataTables.min.css"
import "bootstrap-select/dist/css/bootstrap-select.css"
import "../../../css/bootcomplete.css"
import "../../../css/jquery.datetimepicker.css"
import "../../../css/jquery.datetimepicker.css"
import "../../../css/chat-dock.css"
import "../../../css/accounting.css"
import "../../../css/create_invoice.css"
import AxiosInstance from "../../../services/axiosInstance"
import { getUserName } from "../Doc/Service/docHelper"
import { getCurrencies, getLanguages } from "../../Global/apiHelpers"
import InvoiceModal from "../Popups/invoiceModal"
import { Link } from "gatsby"
import History from "../../Contacts/Detail/HistoryNote/history"
import {
  PRIVATE_NOTE_TYPE,
  PRIVATE_NOTE_ICON,
  privateNoteLabel,
  EMAIL_TYPE,
  SMS_TYPE,
  emailLabel,
  smsLabel,
} from "../../Contacts/Detail/HistoryNote/historyNoteHelper"

const Invoice = ({ fileId }) => {
  const [copyPeoples, setCopyPeoples] = useState([]),
    [invoiceTitle, setInvoiceTitle] = useState("Untitled Invoice"),
    [isShowContactForm, setIsShowContactForm] = useState(true),
    [payor, setPayor] = useState({}),
    [comState, setComState] = useState({
      saveData: false,
      upToDate: { moreOptions: false, ueFlow: false },
      level: 0,
    }),
    [states, setStates] = useState({
      type: "invoice",
      status: "due",
      paymentMethod: "",
      showCommitmentStatus: false,
      breakMilestonePaymentPlan: "0",
      allPartialPayment: "0",
      committedOrNot: "notCommitted",
      chargeOrPayment: "Charge",
      payMethods: [],
      notes: "",
      footerNote: "",
      paymentPlanOptions: "2",
      taxRate: 0.0,
      taxType: "1",
      finalTotal: 0.0,
      form: {
        moreOptions: {},
        ueFlow: {},
        saveData: 0,
      },
      dhAuto: [],
      driveType: "invoice",
      showCreditCardModal: false,
    }),
    [items, setItems] = useState([]),
    [options, setOptions] = useState({
      payment: 0,
      discount: 0,
      shipping: 0,
      installation: 0,
      account: 0,
    }),
    [payee, setPayee] = useState({}),
    [invoiceConsts, setInvoiceConsts] = useState({}),
    [selectedDocId, setSelectedDocId] = useState(0),
    [tags, setTags] = useState([]),
    [tempTags, setTempTags] = useState([]),
    [showHistory, setShowHistory] = useState(false),
    [contact, setContact] = useState(null),
    [contactAllEvent, setContactAllEvent] = useState([]),
    [contactName, setContactName] = useState(""),
    [profilePic, setProfilePic] = useState(process.env.GATSBY_WRANGLER_URL + "StaticImages/avatar.gif"),
    [emails, setEmails] = useState([]),
    [phones, setPhones] = useState([]),
    [addresses, setAddresses] = useState([]),
    [customFields, setCustomFields] = useState([]),
    [historyNotes, setHistoryNotes] = useState([]),
    [historyType, setHistoryType] = useState(PRIVATE_NOTE_TYPE),
    [historyLabel, setHistoryLabel] = useState(privateNoteLabel()),
    [historyValue, setHistoryValue] = useState(null),
    [historyIcon, setHistoryIcon] = useState(PRIVATE_NOTE_ICON),
    [sequenceData, setSequenceData] = useState([]),
    [defaultMessage, setDefaultMessage] = useState("")

  useEffect(async () => {
    const invoiceDetailResponse = await AxiosInstance.get(`/drive/invoice/details/${fileId}`)
    if (invoiceDetailResponse.status == 200) {
      setInvoiceData(invoiceDetailResponse.data.driveInvoice)
    }
  }, [])

  const updateTitle = e => {
    setInvoiceTitle(e.target.value)
  }

  const setInvoiceData = async invoiceDetails => {
    const { ownerID, parentID } = invoiceDetails.driveAll
    setInvoiceConsts({
      ...invoiceConsts,
      ownerID,
      parentID,
      driveId: invoiceDetails.driveAll.id,
      fileUrl: fileId,
      slug: invoiceDetails.driveAll.slug,
    })
    setInvoiceTitle(invoiceDetails.invoiceName)
    setCopyPeoples(invoiceDetails?.content?.copyPeoples ?? [])
    setPayee(invoiceDetails?.content?.payee ?? {})
    setItems(invoiceDetails.content.items ?? [])
    setSelectedDocId(invoiceDetails.driveAll.id)
    const { payment, discount, shipping, installation, account } = invoiceDetails.content
    setOptions({
      payment,
      discount,
      shipping,
      installation,
      account,
    })
    const { driveAll, ...form } = invoiceDetails.driveForm
    setStates({
      ...states,
      notes: invoiceDetails.content.notes,
      footerNote: invoiceDetails.content.footerNote,
      chargeOrPayment: invoiceDetails.content.chargeOrPayment,
      payMethods: invoiceDetails.content.payMethods,
      allPartialPayment: invoiceDetails.content.allPartialPayment,
      breakMilestonePaymentPlan: invoiceDetails.content.breakMilestonePaymentPlan || "0",
      paymentPlanOptions: invoiceDetails.content.paymentPlanOptions ?? 1,
      committedOrNot: invoiceDetails.content.committedOrNot,
      taxRate: invoiceDetails.content.taxRate,
      taxType: invoiceDetails.content.taxType,
      type: invoiceDetails.invoiceType,
      status: invoiceDetails.invoiceStatus,
      form: form,
      dhAuto: (Array.isArray(form.dhAutomation) ? form.dhAutomation : [form.dhAutomation]) || [],
      ezLinkDetail: form.ezLinkDetail || {},
      showForThemPanel: Object.keys(form?.ueFlow || []).length !== 0,
      showDhaPanel: Object.keys(form?.dhAutomation || []).length !== 0,
      showInstallationPanel: Object.keys(form?.ezLinkDetail || []).length !== 0,
      showMoreDetailsPanel: Object.keys(form?.moreOptions || []).length !== 0,
      currencies: await getCurrencies(),
      languages: await getLanguages(),
      objectId: invoiceDetails.id,
      driveId: invoiceDetails.driveAll.id,
      driveSlug: invoiceDetails.driveAll.slug,
      dhaIntroText: "After Payment please…",
    })
    setTags(form?.moreOptions?.doc_private_tags || [])
    setTempTags(form?.moreOptions?.doc_private_tags || [])

    if (invoiceDetails?.content?.payor?.id) {
      getContactData(invoiceDetails.content.payor.id)
      getSequenceList()
    }
  }

  useEffect(async () => {
    if (comState.saveData && comState.level === 1) {
      await saveInvoice()
      setComState({ saveData: false, upToDate: { moreOptions: false, ueFlow: false }, level: 0 })
    }
  }, [comState.saveData, comState.upToDate, comState.level])

  const triggerSave = () => {
    setComState({ ...comState, saveData: true })
  }

  const saveInvoice = async () => {
    try {
      let { type, status, form, dhAuto, ezLinkDetail, ...content } = states
      content = {
        ...content,
        items,
        payor,
        payee,
        copyPeoples,
        ...options,
      }
      const invoiceData = {
        invoiceName: invoiceTitle,
        content,
        form: { ...form, dhAutomation: dhAuto, ezLinkDetail },
        invoiceType: type,
        invoiceStatus: status,
        ...invoiceConsts,
      }
      const saveResponse = await AxiosInstance.post(`/drive/invoice/edit/${fileId}`, invoiceData)

      if (payor.id) {
        setIsShowContactForm(false)
      } else {
        setIsShowContactForm(true)
      }
    } catch (error) {
      console.error("Error when updating invoice:", error.message)
    }
  }

  const fillContactData = contactData => {
    setContact(contactData)
    setContactName(contactData.name)
    if (contactData.profilePic) {
      setProfilePic(process.env.GATSBY_WRANGLER_URL + contactData.profilePic)
    }
    setTags(contactData.tags)
    setTempTags(contactData.tags)
    setEmails(contactData.emails)
    setPhones(contactData.phones)
    setAddresses(contactData.addresses)
    setCustomFields(contactData.contactCustomFields)
    setHistoryNotes(contactData.historyNotes)

    const selectedContact = {
      id: contactData.id,
      name: contactData.name,
      profilePic: contactData.profilePic,
    }
    setPayor(selectedContact)
  }

  const getContactData = async selectedContactId => {
    await AxiosInstance.get(`/contact/detail/${selectedContactId}`).then(response => {
      fillContactData(response.data.contact)
    })
  }

  const getSequenceList = () => {
    AxiosInstance.get(`/drive/sequence`).then(response => {
      setSequenceData(response.data.items)
    })
  }

  const previewUrl = `${process.env.GATSBY_PREVIEW_URL}/${getUserName()}/invoice/${fileId}`

  return (
    <Layout>
      <div id="right-section" className="h-100">
        <div className="row g-0">
          <div className="col" id="page-urls">
            <div className="row g-0">
              <div className="col-sm-12 mb-3">
                <div className="pageTitle_Editor">
                  <Link href="/drive" className="goBack">
                    <i className="bi bi-arrow-90deg-left"></i>
                  </Link>
                  <button type="button" className="CD_UserType isHuman" data-bs-toggle="tooltip">
                    <i className="bi bi-list-ul" />
                  </button>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Untitled Invoice"
                    value={invoiceTitle}
                    onChange={updateTitle}
                  />
                </div>
              </div>
            </div>

            <div className="row g-0">
              <div className="col-lg-12">
                <div className="row g-0" id="invoiceDetailTemplate">
                  {/*  People & Permissions */}
                  <People
                    payee={payee}
                    setPayee={setPayee}
                    payor={payor}
                    setPayor={setPayor}
                    copyPeoples={copyPeoples}
                    setCopyPeoples={setCopyPeoples}
                    isShowContactForm={isShowContactForm}
                    setIsShowContactForm={setIsShowContactForm}
                  />

                  {/* Invoice Panel */}
                  <Details
                    items={items}
                    setItems={setItems}
                    states={states}
                    setStates={setStates}
                    options={options}
                    setOptions={setOptions}
                    fileId={fileId}
                    selectedDocId={selectedDocId}
                    invoiceConsts={invoiceConsts}
                    comState={comState}
                    setComState={setComState}
                    payor={payor}
                    tags={tags}
                    setTags={setTags}
                    tempTags={tempTags}
                    setTempTags={setTempTags}
                    previewUrl={previewUrl}
                    setShowHistory={setShowHistory}
                    setHistoryType={setHistoryType}
                    setHistoryLabel={setHistoryLabel}
                    setDefaultMessage={setDefaultMessage}
                    emails={emails}
                    phones={phones}
                    setHistoryValue={setHistoryValue}
                  />

                  {showHistory && payor?.id && (
                    <History
                      contact={contact}
                      contactName={contactName}
                      profilePic={profilePic}
                      emails={emails}
                      phones={phones}
                      addresses={addresses}
                      customFields={customFields}
                      historyNotes={historyNotes}
                      setHistoryNotes={setHistoryNotes}
                      setShowHistory={setShowHistory}
                      historyType={historyType}
                      setHistoryType={setHistoryType}
                      historyLabel={historyLabel}
                      setHistoryLabel={setHistoryLabel}
                      historyIcon={historyIcon}
                      setHistoryIcon={setHistoryIcon}
                      historyValue={historyValue}
                      setHistoryValue={setHistoryValue}
                      sequenceData={sequenceData}
                      contactAllEvent={contactAllEvent}
                      selectedContactId={payor.id}
                      defaultMessage={defaultMessage}
                    />
                  )}

                  <div className="col-lg-12 text-center">
                    <div className="btn-group btnAddNewDropdown">
                      <button type="button" onClick={triggerSave} className="btn btn-primary btn-lg my-1">
                        <i className="bi bi-check2"></i> Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-lg my-1 dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-files"></i> Save As Copy
                          </a>
                        </li>
                      </ul>
                    </div>{" "}
                    <a href={previewUrl} type="button" className="btn btn-secondary btn-lg" target="_blank">
                      <i className="bi bi-box-arrow-up-right" /> Preview
                    </a>{" "}
                    <div className="btn-group btnAddNewDropdown btnSendWithSequence">
                      <button
                        type="button"
                        className="btn btn-magic btn-lg my-1 btnOpenChatDockEmail"
                        data-type="Email"
                        data-value="amitabh@domain.com"
                      >
                        <i className="bi bi-send-fill"></i> Send
                      </button>
                      <button
                        type="button"
                        className="btn btn-magic btn-lg my-1 dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item btnCopyInvoiceURL"
                            role="button"
                            tabIndex={0}
                            data-bs-toggle="tooltip"
                            title="Copy"
                          >
                            <i className="bi bi-files"></i> Copy Link
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item btnOpenChatDockEmail"
                            href="#"
                            onClick={() => {
                              setShowHistory(true)
                              setHistoryType(EMAIL_TYPE)
                              setHistoryLabel(emailLabel(emails[0]?.email))
                              setDefaultMessage(previewUrl)
                              setHistoryValue(emails[0]?.email)
                            }}
                          >
                            <i className="bi bi-envelope-fill" /> Send as Email
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item btnOpenChatDockSMS"
                            href="#"
                            onClick={() => {
                              setShowHistory(true)
                              setHistoryType(SMS_TYPE)
                              setDefaultMessage(previewUrl)
                              setHistoryLabel(smsLabel(phones[0]?.phone))
                            }}
                          >
                            <i className="bi bi-phone-fill" /> Send as SMS
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-12 text-center">
                      {!showHistory && payor?.id && (
                        <button
                          type="button"
                          className="btn btn-default btn-lg btnOpenChatDock"
                          data-bs-toggle="tooltip"
                          title="History"
                          onClick={() => setShowHistory(true)}
                        >
                          <i className="bi bi-arrow-counterclockwise" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InvoiceModal />
      </div>
    </Layout>
  )
}

export default Invoice
