import * as React from "react"
import { Link } from "gatsby"
import { useEffect, useRef, useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import { Accordion, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap"
import {
  CONTACT_PHONE_OPTIONS,
  CONTACT_ADDRESS_OPTIONS,
  DEFAULT_CONTACT_PHONE_OPTION,
  DEFAULT_CONTACT_ADDRESS_OPTION,
  TASK_EMAIL_OPTIONS,
  DEFAULT_TASK_EMAIL_OPTION,
  PRIVACY_PUBLIC,
} from "../../../services/globalStaticData"
import { getLoginUserId } from "../../../services/auth"
import PlacesAutocomplete from "react-places-autocomplete"
import { geocodeByAddress } from "react-places-autocomplete"
import { ADD_NEW_CONTACT, SEARCH_EXISTING_CONTACTS } from "../../Tasks/taskHelper"
import "../../Tasks/task_detail.css"
import { CONTACT_TYPE_HUMAN } from "../../Contacts/contactHelper"

const People = ({
  payor,
  setPayor,
  payee,
  setPayee,
  copyPeoples,
  setCopyPeoples,
  isShowContactForm,
  setIsShowContactForm,
}) => {
  const [uploadedFile, setUploadedFile] = useState(),
    [address, setAddress] = useState(""),
    [defaultSelectedRole, setDefaultSelectedRole] = useState("payor")

  const [defaultUserOptionSelected, setDefaultUserOptionSelected] = useState(ADD_NEW_CONTACT),
    [jobLabel, setJobLabel] = useState("Home"),
    [jobIcon, setJobIcon] = useState("briefcase-fill"),
    [addressIcon, setAddressIcon] = useState("house-door-fill"),
    [uploadedFilePath, setUploadedFilePath] = useState("StaticImages/avatar.gif"),
    [uploadFile, setUploadFile] = useState(false),
    [selectedPhoneLabel, setSelectedPhoneLabel] = useState(DEFAULT_CONTACT_PHONE_OPTION.label),
    [selectedPhoneIcon, setSelectedPhoneIcon] = useState(DEFAULT_CONTACT_PHONE_OPTION.icon),
    [selectedAddressLabel, setSelectedAddressLabel] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.label),
    [selectedEmailLabel, setSelectedEmailLabel] = useState(DEFAULT_TASK_EMAIL_OPTION.label),
    [selectedEmailIcon, setSelectedEmailIcon] = useState(DEFAULT_TASK_EMAIL_OPTION.icon),
    [userSearchResult, setUserSearchResult] = useState([]),
    [existingUserDefaultName, setExistingUserDefaultName] = useState(""),
    [existingUserDefaultId, setExistingUserDefaultId] = useState(""),
    [existingUserDefaultImage, setExistingUserDefaultImage] = useState(""),
    [existingContactId, setExistingContactId] = useState(""),
    [selectedPhoneType, setSelectedPhoneType] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),
    [selectedAddressType, setSelectedAddressType] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.type)

  const roles = { payee: "Payee / Receiving money", payor: "Payor / Sending money" }

  const companyPhotoRef = useRef(null)
  const contactImage = useRef(null)
  const openSelectImageBox = () => {
    contactImage.current.click()
  }

  const removeInvoiceContact = (contactId, role) => {
    switch (role) {
      case "payee":
        setPayee({})
        break
      case "payor":
        setPayor({})
        setIsShowContactForm(true)
        break
    }
  }

  const handleUserFieldDisplay = selectedValue => {
    if (selectedValue === ADD_NEW_CONTACT) {
      setDefaultUserOptionSelected(ADD_NEW_CONTACT)
    } else if (selectedValue === SEARCH_EXISTING_CONTACTS) {
      setDefaultUserOptionSelected(SEARCH_EXISTING_CONTACTS)
    } else if (selectedValue === "Add Group / Audience") {
      setDefaultUserOptionSelected("Add Group / Audience")
    }
  }

  const ChooseCompanyPhoto = () => {
    if (companyPhotoRef.current) {
      companyPhotoRef.current.click() // Trigger click event of the file input element
    }
  }

  const onImageChange = e => {
    setUploadedFile(e.target.files[0])
    setUploadFile(true)
  }

  const handleUpload = () => {
    let formData = new FormData()
    formData.append("ProfilePic", uploadedFile)
    formData.append("user_id", getLoginUserId())
    formData.append("parent_id", "0")
    AxiosInstance.post(`/settings/general-setting/upload-file-r2`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if (response.status === 201) {
          setUploadedFilePath(response.data.profilePic.filePath)
          setUploadedFile(response.data.profilePic.filePath)
        }
      })
      .catch(function (error) {})
  }

  const handleChangeAddress = address => {
    console.log(address)
    setAddress(address)
  }

  const handleSelectAddress = address => {
    geocodeByAddress(address)
      .then(results => {
        setAddress(results[0].formatted_address)
      })
      .catch(error => console.error("Error", error))
  }

  const contactSearchData = e => {
    let searchUser = e.target.value
    if (searchUser.length < 2) {
      setUserSearchResult([])
      setExistingContactId("")
      return false
    }

    AxiosInstance.get(`/contact/`, {
      params: {
        page: 1,
        search: searchUser ?? "",
      },
    }).then(response => {
      const displayedItems = response.data.items.slice(0, 10)
      setUserSearchResult(displayedItems)
    })
  }

  const linkExistingContactWithInvoice = () => {
    const selectedContact = {
      id: existingContactId,
      name: existingUserDefaultName,
      profilePic: existingUserDefaultImage,
    }

    assingContactToInvoice(selectedContact)
  }

  useEffect(() => {
    if (uploadFile) {
      setUploadFile(false)
      handleUpload()
    }
  }, [uploadFile])

  useEffect(() => {
    if (payor.id) {
      setIsShowContactForm(false)
    }
  }, [payor])

  const assingContactToInvoice = contactData => {
    switch (defaultSelectedRole) {
      case "payee":
        setPayee(contactData)
        break
      case "payor":
        setPayor(contactData)
        break
    }
  }

  const saveContact = e => {
    e.preventDefault()
    const formData = new FormData(e.target)

    if (formData.get("contactName") === "") {
      alert("Please enter Contact Name.")
      return false
    }

    let contactData = {
      name: formData.get("contactName"),
      email: formData.get("contactEmail"),
      profilePic: uploadedFile,
      companyName: formData.get("companyName"),
      jobTitle: formData.get("jobTitle"),
      contactType: CONTACT_TYPE_HUMAN,
      phone: {
        type: selectedPhoneType,
        phone: formData.get("contactPhone"),
        permission: PRIVACY_PUBLIC,
      },
      address: {
        type: selectedAddressType,
        address: address,
        permission: PRIVACY_PUBLIC,
      },
      metaData: [],
      customFields: [],
    }

    return new Promise(async () => {
      try {
        const response = await AxiosInstance.post(`/contact/add`, contactData)
        if (response.status === 201) {
          assingContactToInvoice({
            id: response.data.contact,
            name: contactData.name,
            profilePic: uploadedFile,
          })
          setAddress("")
          e.target.reset()
        }
      } catch (error) {
        console.error(error)
        alert("There is some error. Please try again later.")
      }
    })
  }

  const renderContact = (contactData, role) => {
    if (contactData.id > 0)
      return (
        <div className="col-lg-6">
          <div className="teamMember">
            <OverlayTrigger overlay={<Tooltip>Remove user?</Tooltip>}>
              <Link
                to={"#"}
                className="btnRemoveEventUser"
                onClick={e => {
                  e.preventDefault()
                  removeInvoiceContact(contactData.id, role)
                }}
              >
                <i className="bi bi-x-lg" />
              </Link>
            </OverlayTrigger>

            <div className="userAvatar">
              <img
                src={`${process.env.GATSBY_WRANGLER_URL}${
                  contactData.profilePic ? contactData.profilePic : uploadedFilePath
                }`}
                width={40}
                height={40}
              />
            </div>
            <div className="userInfo">
              <Link to={`/contacts/detail/${contactData.id}`}>
                <strong>{contactData.name}</strong>
              </Link>
              <strong className="ms-1">{roles[role]}</strong>

              <div className="contactListQuickTools ms-2">
                {contactData?.emails && contactData.emails.length >= 0 && contactData.emails[0]?.email && (
                  <OverlayTrigger overlay={<Tooltip>{contactData.emails[0].email ?? ""}</Tooltip>}>
                    <Link
                      to={`/contacts/detail/${contactData.id}?email=${contactData.emails[0].email}`}
                      className="btnOpenChatDockEmail"
                    >
                      <i className="bi bi-envelope-fill" />
                    </Link>
                  </OverlayTrigger>
                )}

                {contactData?.phones && contactData.phones[0]?.phone && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {contactData.phones[0].phone ? `SMS to ${contactData.phones[0].phone}` : ""}
                      </Tooltip>
                    }
                  >
                    <Link to={"#"} className="btnOpenChatDockSMS">
                      <i className="bi bi-phone-fill" />
                    </Link>
                  </OverlayTrigger>
                )}

                {contactData?.phones && contactData.phones[0]?.phone && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {contactData.phones[0].phone ? `Call to ${contactData.phones[0].phone}` : ""}
                      </Tooltip>
                    }
                  >
                    <Link to={"#"} className="btnOpenChatDockPhoneCampaignDirectCall">
                      <i className="bi bi-telephone-fill" />
                    </Link>
                  </OverlayTrigger>
                )}

                {contactData?.addresses && contactData.addresses[0].address && (
                  <OverlayTrigger overlay={<Tooltip>{contactData.addresses[0].address ?? ""}</Tooltip>}>
                    <Link to={"#"} className="btnOpenChatDockPostalMail">
                      <i className="bi bi-geo-alt-fill" />
                    </Link>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </div>
        </div>
      )
  }

  return (
    <Accordion defaultActiveKey="0" id="peoplePanelAccordion" className="mb-3">
      <Accordion.Item eventKey="0" className="panel panel-green">
        <Accordion.Header>
          <span className="panel-heading-icon">
            <i className="bi bi-people-fill" />
          </span>
          <span className="panel-heading-title">People & Permissions</span>
        </Accordion.Header>
        <Accordion.Body id="people_container">
          <div className="row showUserDetailsContainer">
            {renderContact(payee, "payee")}
            {renderContact(payor, "payor")}
          </div>

          <div
            className={`row g-0 invoiceAddTeamUser mt-3 socialSearchModal${
              !isShowContactForm ? " d-none" : ""
            }`}
          >
            <div className="col-lg-9 col-md-12 mx-auto p-0 BC_Multiple_User">
              <div className="colHeading position-relative">
                <strong>
                  <i className="bi bi-person-plus-fill" /> People &amp; Roles
                </strong>
              </div>
              <div className="dh-block-body" style={{ padding: "15px" }}>
                <form method="POST" onSubmit={saveContact}>
                  <div className="d-block addNewSocialUserRow">
                    <div className="row BC_Multiple_User">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-5 mb-1 mb-lg-0">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="userOption"
                                id="addNewContact"
                                value={ADD_NEW_CONTACT}
                                checked={defaultUserOptionSelected === ADD_NEW_CONTACT}
                                onChange={e => handleUserFieldDisplay(e.target.value)}
                              />{" "}
                              <label className="form-check-label" htmlFor="addNewContact">
                                Add New Contact
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="userOption"
                                id="searchExistingContacts"
                                value={SEARCH_EXISTING_CONTACTS}
                                checked={defaultUserOptionSelected === SEARCH_EXISTING_CONTACTS}
                                onChange={e => handleUserFieldDisplay(e.target.value)}
                              />{" "}
                              <label className="form-check-label" htmlFor="searchExistingContacts">
                                Search Contacts
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-3 mb-1 mb-lg-0">
                            <select
                              className="form-select"
                              value={defaultSelectedRole}
                              onChange={e => {
                                setDefaultSelectedRole(e.target.value)
                              }}
                              style={{ backgroundColor: "#f4fbeb" }}
                            >
                              <option value="payor">Payor / Sending money</option>
                              <option value="payee">Payee / Receiving money</option>
                            </select>
                          </div>
                          <div className="col-lg-4 mb-1 mb-lg-0 text-end">
                            <div className="dropdown socialActionShareWith">
                              <Dropdown>
                                <Dropdown.Toggle className="btn dropdown-toggle" type="button" variant="">
                                  {" "}
                                  <i className="bi bi-link-45deg"></i> Attached, Not Shared
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item href="#">
                                    <i className="bi bi-link-45deg"></i> Attached, Not Shared
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-2 optionalMsgContainer d-none">
                            <input type="text" className="form-control" placeholder="Optional Message " />
                          </div>
                        </div>
                      </div>

                      <div
                        className={`col-lg-12 mt-2 searchExistingUserField position-relative ${
                          defaultUserOptionSelected === SEARCH_EXISTING_CONTACTS ? "d-block" : "d-none"
                        }`}
                      >
                        <div className="bc-wrapper">
                          <input
                            type="hidden"
                            id="socialAutoSearchId"
                            name="socialAutoSearchId"
                            defaultValue={existingUserDefaultId}
                          />

                          <input
                            type="text"
                            className={`form-control ${existingContactId ? "userSelectedInput" : ""} `}
                            name="socialAutoSearch"
                            placeholder="Name, Phone or Email"
                            autoComplete="off"
                            onKeyUp={contactSearchData}
                            data-query={existingUserDefaultName}
                            value={existingUserDefaultName}
                            onChange={e => setExistingUserDefaultName(e.target.value)}
                          />

                          {userSearchResult.length > 0 && (
                            <div className="bc-menu list-group" style={{ display: "block" }}>
                              {userSearchResult.map((searchUserData, searchUserIndex) => {
                                return (
                                  <a
                                    // href="#"
                                    className="list-group-item"
                                    data-id={searchUserData.id}
                                    data-label={searchUserData.name}
                                    key={searchUserIndex}
                                    onClick={() => {
                                      const defaultImageUrl = `${
                                        searchUserData.profilePic
                                          ? searchUserData.profilePic
                                          : uploadedFilePath
                                      }`
                                      setExistingContactId(searchUserData.id)
                                      setExistingUserDefaultName(searchUserData.name)
                                      setExistingUserDefaultImage(defaultImageUrl)
                                      setUserSearchResult([])
                                      setExistingUserDefaultId(searchUserData.id)
                                    }}
                                  >
                                    <img
                                      className="bc-user-avatar"
                                      src={`${process.env.GATSBY_WRANGLER_URL}/${
                                        searchUserData.profilePic
                                          ? searchUserData.profilePic
                                          : uploadedFilePath
                                      }`}
                                    />
                                    {searchUserData.name}
                                  </a>
                                )
                              })}
                            </div>
                          )}

                          {existingContactId && (
                            <img
                              className="selectedUserAvatar"
                              src={`${process.env.GATSBY_WRANGLER_URL}/${existingUserDefaultImage}`}
                              alt={existingUserDefaultName}
                            />
                          )}
                        </div>
                        {existingContactId && (
                          <button
                            type={"button"}
                            className={`btn btnBootCompleteExitingUser btnSavePeopleRow`}
                            onClick={() => {
                              linkExistingContactWithInvoice()
                            }}
                          >
                            <i className="bi bi-check2" /> Save
                          </button>
                        )}
                      </div>
                      <div
                        className={`col-lg-12 mt-2 addNewUserField ${
                          defaultUserOptionSelected === ADD_NEW_CONTACT ? "d-block" : "d-none"
                        }`}
                      >
                        <div className="row">
                          <div className="col-lg-9">
                            <div className="mb-3">
                              <div className="row">
                                <div className="col-md-6 mb-3 mb-md-0">
                                  <div className="input-group">
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Human</Tooltip>}>
                                      <button className="btn btnDefaultColor addContactUserType isHuman">
                                        <i className="bi bi-person-fill" />
                                      </button>
                                    </OverlayTrigger>

                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Contact Name"
                                      name="contactName"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-group">
                                    <div className="dropdown addNewPhoneBox">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          className="btn btn-default-custom dropdown-toggle"
                                          type="button"
                                          variant=""
                                        >
                                          {" "}
                                          <i className={selectedPhoneIcon} />
                                          <span>{selectedPhoneLabel}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {CONTACT_PHONE_OPTIONS.map((phoneData, phoneDataIndex) => {
                                            return (
                                              <Dropdown.Item
                                                href="#"
                                                key={phoneDataIndex}
                                                onClick={() => {
                                                  setSelectedPhoneIcon(phoneData.icon)
                                                  setSelectedPhoneLabel(phoneData.label)
                                                  setSelectedPhoneType(phoneData.type)
                                                }}
                                              >
                                                <i className={phoneData.icon} /> {phoneData.label}
                                              </Dropdown.Item>
                                            )
                                          })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control contact_phone_us"
                                      placeholder="Phone"
                                      name="contactPhone"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3 addContactUserTypeContactRow d-none">
                              <div className="input-group">
                                <div className="input-group-text">
                                  <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/no-image.jpg`} />
                                  <input
                                    type="file"
                                    accept="image/*"
                                    className="contactPhotoFile"
                                    style={{
                                      visibility: "hidden",
                                      position: "absolute",
                                      left: "-10000px",
                                    }}
                                  />
                                  <OverlayTrigger placement="top" overlay={<Tooltip>Contact avatar</Tooltip>}>
                                    <Link to={"#"} className="btnAddCompanyPhoto">
                                      <i className="bi bi-pencil-fill" />
                                    </Link>
                                  </OverlayTrigger>
                                </div>
                                <input
                                  type="text"
                                  className="form-control CD_input_info"
                                  placeholder="Contact Name"
                                />
                              </div>
                            </div>
                            <div className="mb-3 addContactUserTypeCompanyRow">
                              <div className="row">
                                <div className="col-md-6 mb-3 mb-md-0">
                                  <div className="input-group">
                                    <div className="input-group-text">
                                      <img
                                        src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/no-image.jpg`}
                                      />
                                      <input
                                        ref={companyPhotoRef}
                                        type="file"
                                        accept="image/*"
                                        className="companyPhotoFile"
                                        name="companyPhoto"
                                        style={{
                                          visibility: "hidden",
                                          position: "absolute",
                                          left: "-10000px",
                                        }}
                                      />

                                      <OverlayTrigger overlay={<Tooltip>Company Photo</Tooltip>}>
                                        <Link
                                          to="#"
                                          className="btnAddCompanyPhoto"
                                          onClick={e => {
                                            e.preventDefault() // Prevent default link behavior
                                            ChooseCompanyPhoto() // Call the function to trigger file input click
                                          }}
                                        >
                                          <i className="bi bi-pencil-fill" />
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control CD_input_info contact_company"
                                      placeholder="Company"
                                      name="companyName"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-group">
                                    <div className="dropdown addNewPhoneBox">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          className="btn btn-default-custom dropdown-toggle"
                                          type="button"
                                          variant=""
                                        >
                                          {" "}
                                          <i className={`bi bi-${jobIcon}`} />
                                          <span>{jobLabel}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            href="#"
                                            onClick={() => {
                                              setJobIcon("house-door-fill")
                                              setJobLabel("Mailing")
                                            }}
                                          >
                                            <i className="bi bi-house-door-fill" /> Mailing
                                          </Dropdown.Item>

                                          <Dropdown.Item
                                            href="#"
                                            onClick={() => {
                                              setJobIcon("house-door-fill")
                                              setJobLabel("Billing")
                                            }}
                                          >
                                            <i className="bi bi-house-door-fill" /> Billing
                                          </Dropdown.Item>

                                          <Dropdown.Item
                                            href="#"
                                            onClick={() => {
                                              setJobIcon("house-door-fill")
                                              setJobLabel("Work")
                                            }}
                                          >
                                            <i className="bi bi-house-door-fill" /> Work
                                          </Dropdown.Item>

                                          <Dropdown.Item
                                            href="#"
                                            onClick={() => {
                                              setJobIcon("house-door-fill")
                                              setJobLabel("Other")
                                            }}
                                          >
                                            <i className="bi bi-house-door-fill" /> Other
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Job Title"
                                      name="jobTitle"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="row">
                                <div className="col-md-6 mb-3 mb-md-0">
                                  <div className="input-group">
                                    <div className="dropdown addNewPhoneBox">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          className="btn btn-default-custom dropdown-toggle"
                                          type="button"
                                          variant=""
                                        >
                                          {" "}
                                          <i className={selectedEmailIcon} />
                                          <span>{selectedEmailLabel}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {TASK_EMAIL_OPTIONS.map((emailData, emailDataIndex) => {
                                            return (
                                              <Dropdown.Item
                                                href="#"
                                                key={emailDataIndex}
                                                onClick={() => {
                                                  setSelectedEmailIcon(emailData.icon)
                                                  setSelectedEmailLabel(emailData.label)
                                                  // setSelectedEmailType(emailData.type);
                                                }}
                                              >
                                                <i className={emailData.icon} /> {emailData.label}
                                              </Dropdown.Item>
                                            )
                                          })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control CD_input_info contact_email"
                                      placeholder="Email"
                                      name="contactEmail"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="input-group">
                                    <div className="dropdown addNewPhoneBox">
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          className="btn btn-default-custom dropdown-toggle"
                                          type="button"
                                          variant=""
                                        >
                                          {" "}
                                          <i className={`bi bi-${addressIcon}`} />
                                          <span>{selectedAddressLabel}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          {CONTACT_ADDRESS_OPTIONS.map((addressData, addressDataIndex) => {
                                            return (
                                              <Dropdown.Item
                                                href="#"
                                                key={addressDataIndex}
                                                onClick={() => {
                                                  setSelectedAddressLabel(addressData.label)
                                                  setSelectedAddressType(addressData.type)
                                                }}
                                              >
                                                <i className={addressData.icon} /> {addressData.label}
                                              </Dropdown.Item>
                                            )
                                          })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>

                                    <PlacesAutocomplete
                                      value={address}
                                      onChange={handleChangeAddress}
                                      onSelect={handleSelectAddress}
                                    >
                                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <>
                                          <input
                                            {...getInputProps({
                                              placeholder: "Search Places ...",
                                              className: "location-search-input form-control",
                                              name: "address",
                                            })}
                                          />
                                          <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion, index) => {
                                              const className = suggestion.active
                                                ? "suggestion-item--active"
                                                : "suggestion-item"
                                              const style = suggestion.active
                                                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                : { backgroundColor: "#ffffff", cursor: "pointer" }
                                              return (
                                                <div
                                                  {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                  })}
                                                  key={index}
                                                >
                                                  <span>{suggestion.description}</span>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </>
                                      )}
                                    </PlacesAutocomplete>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="addNewContactAvatar d-inline-block">
                              <img
                                src={`${process.env.GATSBY_WRANGLER_URL}${uploadedFilePath}`}
                                alt="Contact avatar"
                                className="contact_pic"
                              />
                              <OverlayTrigger placement="top" overlay={<Tooltip>Contact avatar</Tooltip>}>
                                <a className="btnEditAvatar" onClick={openSelectImageBox}>
                                  <span className="d-block" data-bs-toggle="tooltip" title="Contact avatar">
                                    <i className="bi bi-pencil-fill" />
                                  </span>
                                </a>
                              </OverlayTrigger>
                              <input type="file" ref={contactImage} onChange={onImageChange} hidden />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <button type="submit" className="btn btn-primary">
                              <i className="bi bi-check2" /> Save
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-lg-12 mt-2 addNewGroupAudienceField ${
                          defaultUserOptionSelected === "Add Group / Audience" ? "d-block" : "d-none"
                        }`}
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input-group">
                              <select className="form-select">
                                <option>==GROUP / AUDIENCE==</option>
                              </select>
                              <button className="btn btn-primary">
                                <i className="bi bi-check2" /> Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className={`text-center mt-3`}>
            <button
              type="button"
              class={`event_btnAddNewGuestUser${isShowContactForm ? " active" : ""}`}
              onClick={() => {
                setIsShowContactForm(!isShowContactForm)
              }}
            >
              <i class="bi bi-person-fill-add"></i>
            </button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
export default People
